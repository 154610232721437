<style lang="less">
  @import './login.less';
</style>

<template>
  <div class="login" @keydown.enter="handleSubmit">
    <div class="login-con">
      <Card :bordered="false">
        <p slot="title">
          <Icon type="log-in"></Icon>
          <span>欢迎登录（茶圈党建）后台</span>
        </p>
        <div class="form-con">
          <Form ref="loginForm" :model="admin" :rules="rules">
            <FormItem prop="account">
              <Input v-model="admin.username" placeholder="请输入用户名">
              <span slot="prepend">
                <Icon :size="16" type="person"></Icon>
              </span>
              </Input>
            </FormItem>
            <FormItem prop="password">
              <Input type="password" v-model="admin.password" placeholder="请输入密码">
              <span slot="prepend">
                <Icon :size="14" type="locked"></Icon>
              </span>
              </Input>
            </FormItem>
            <FormItem>
              <Button @click="handleSubmit" type="primary" long>登录</Button>
            </FormItem>
          </Form>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
  import {login} from '../axios/api/admin';
  export default {
    data() {
      return {
        returnUrl: '',
        admin: {
          username: '',
          password: ''
        },
        rules: {
          username: [
            {required: true, message: '账号不能为空', trigger: 'blur'}
          ],
          password: [
            {required: true, message: '密码不能为空', trigger: 'blur'}
          ]
        }
      };
    },
    created() {
      console.log(localStorage.searchStatus)
      this.returnUrl = this.$route.query.returnUrl || location.origin + location.pathname;
    },
    methods: {
      handleSubmit() {
        let that = this;
        console.log('登录')
        this.$refs.loginForm.validate(
          (valid) => {
            if (valid) {
              login(that.admin).then(
                res => {
                  console.log("deng", res.data);
                  // localStorage.removeItem('pageOpenedList')
                  // location.href = decodeURIComponent(that.returnUrl);
                  // localStorage.setItem('token', res.data.data.sid)
                  localStorage.setItem('user', JSON.stringify(res.data.data));
                  localStorage.removeItem('pageOpenedList')
                  // location.href = decodeURIComponent(that.returnUrl);
                  this.$router.push({path:'/info/list'})
                },
                err => {
                  console.error(err);
                }
              );
            }
          }
        );
      }
    }
  };
</script>
